const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://taxidriver.dvgeo.app' : 'http://192.168.100.106:3058',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://taxidriver.dvgeo.app' : 'http://192.168.100.106:3058',
    api: 'api/',
    apiSocket: 'taxidriver::1.2',
    nameDir: 'taxidriver',
    package: 'app.dvgeo.taxidriver.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyBNFFsPHDnRMjMiP7JodVLlA6Bz_YG27c4',
    appName: 'TaxiDriver',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#F7AE3F',
    colorDark: '#000000',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.taxidriver',
    playStoreDriverId: 'app.dvgeo.taxidriver.driver',
    appStorePassengerId: '6737130926',
    appStoreDriverId: '6737130954',
    email: "Taxidriverve@gmail.com",
};
export default config;
